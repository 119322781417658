<template>
  <div class="container">
    <div class="wrapper">
      <!--      <div class="tabs">-->
      <!--        <div :class="['tab-item', {'tab-active': tabName === index}]" v-for="(item, index) in tabs" :key="index" @click="handleTabChange(index)">{{item.label}}</div>-->
      <!--      </div>-->
      <van-tabs :value="tabName" background="transparent" title-inactive-color="#fff" title-active-color="#fff" color="#fff" @change="handleTabChange">
        <van-tab title="总榜" name="-1" :title-style="{fontWeight: 'bold'}"></van-tab>
        <van-tab title="国际" name="1" :title-style="{fontWeight: 'bold'}"></van-tab>
        <van-tab title="公寓" name="0" :title-style="{fontWeight: 'bold'}"></van-tab>
        <van-tab title="社区" name="2" :title-style="{fontWeight: 'bold'}"></van-tab>
        <van-tab title="启航" name="3" :title-style="{fontWeight: 'bold'}"></van-tab>
      </van-tabs>

      <div class="date" @click="handleDateShow">
        {{ dateInfo.value | formatDate }}
        <van-icon name="arrow-down" size="12px" />
      </div>

      <div class="rank">
        <div class="rank-podium">
          <div class="rank-podium-item">
            <p class="rank-podium-title">{{ (topThreeList[1] && topThreeList[1].name) || '-' }}</p>
            <p class="rank-podium-num">{{ (topThreeList[1] && topThreeList[1].total) || '-' }} <span>单</span></p>
          </div>
          <div class="rank-podium-item no1">
            <p class="rank-podium-title">{{ (topThreeList[0] && topThreeList[0].name) || '-' }}</p>
            <p class="rank-podium-num">{{ (topThreeList[0] && topThreeList[0].total) || '-' }} <span>单</span></p>
          </div>
          <div class="rank-podium-item">
            <p class="rank-podium-title">{{ (topThreeList[2] && topThreeList[2].name) || '-' }}</p>
            <p class="rank-podium-num">{{ (topThreeList[2] && topThreeList[2].total) || '-' }} <span>单</span></p>
          </div>
        </div>
        <div class="rank-list">
          <div class="rank-list-item">
            <p class="rank-list-index">排名</p>
            <p class="rank-list-title">姓名</p>
            <p class="rank-list-num">业绩</p>
          </div>
          <div class="rank-list-item" v-for="(item) in otherList" :key="item.id">
            <p class="rank-list-index">{{ item.no }}</p>
            <p class="rank-list-title">{{ item.name }}</p>
            <p class="rank-list-num">{{ item.total }}<span v-if="item.total.indexOf('...') === -1">单</span></p>
          </div>
          <van-empty v-if="!otherList.length" description="无数据"></van-empty>
        </div>
      </div>

      <!-- 弹窗 -->
      <van-popup v-model='dateInfo.show' position='bottom'>
        <van-datetime-picker
          :value="dateInfo.value"
          type="year-month"
          title="选择年月"
          :min-date="dateInfo.minDate"
          :formatter="formatter"
          @confirm="handleDateConfirm"
          @cancel="handleDateCancel"
        />
    </van-popup>
    </div>
  </div>
</template>

<script>
  import { getRankListApi } from "@/api/h5"
  import dayjs from 'dayjs'
  export default {
    name: 'RankingIndex',
    data() {
      return {
        tabName: '-1',
        dateInfo: {
          show: false,
          value: new Date(),
          minDate: new Date(2024, 0, 1)
        },

        topThreeList: [],
        otherList: []
      }
    },
    computed: {
    },
    filters: {
      formatDate(val) {
        return dayjs(val, 'YYYY-MM').format('YYYY年MM月')
      }
    },
    created() {
      this.getRankList()
    },
    methods: {
      dayjs,
      getRankList() {
        let params = {
          series: this.tabName,
          month: this.dayjs(this.dateInfo.value).format('YYYY-MM'),
          userId: this.$route.query.userId
        }
        this.$store.dispatch('base/SetLoading', true)
        getRankListApi(params).then(res => {
          if(res.data.code === 200) {
            console.log(res.data.result, 'result')
            this.topThreeList = res.data.result.qianSanList || []
            this.otherList = res.data.result.xiaList || []
          }
        }).catch(() => {
          this.$toast('系统开小差')
        }).finally(() => {
          this.$store.dispatch('base/SetLoading', false)
        })
      },
      handleTabChange(name) {
        this.tabName = name
        this.getRankList()
      },
      handleDateShow() {
        this.dateInfo.show = true
      },
      handleDateConfirm(date) {
        this.dateInfo.value = date
        this.dateInfo.show = false
        this.getRankList()
      },
      handleDateCancel() {
        this.dateInfo.show = false
      },
      formatter(type, val) {
        if (type === 'year') {
          return `${val}年`
        } else if (type === 'month') {
          return `${val}月`
        }
        return val
      }
    }
  }
</script>

<style scoped lang="less">
  .container{
    width: 100%;
    overflow: auto;
    .wrapper{
      height: 1000px;
      background: url("../../../assets/h5/ranking_bg.png") no-repeat left top / 100% 100%;
      padding-top: 35px;
      .tabs{
        display: flex;
        justify-content: space-evenly;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        .tab-item{
          padding-bottom: 3px;
          border-bottom: 3px solid transparent;
        }
        .tab-active{
          border-bottom-color: #fff;
        }
      }

      .date{
        width: 112px;
        height: 22px;
        line-height: 22px;
        margin: 10px auto 0;
        border-radius: 10px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        color: #fff;
        background: rgba(255,255,255, 0.3);
      }

      .rank{
        margin-top: 300px;
        padding: 0 14px;
        .rank-podium{
          width: 100%;
          height: 128px;
          color: #D58331;
          text-align: center;
          background: url("../../../assets/h5/ranking_podium.png") no-repeat left top / 100% 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          .rank-podium-item{
            flex: 1;
            position: relative;
            bottom: 15px;
            .rank-podium-title{
              font-size: 12px;
              padding-bottom: 5px;
            }
            .rank-podium-num{
              font-size: 15px;
              font-weight: bold;
              span{
                font-weight: normal;
                font-size: 12px;
              }
            }
          }
          .no1{
            bottom: 20px;
          }
        }

        .rank-list{
          font-size: 12px;
          color: #fff;
          background: rgba(0,0,0,0.2);
          padding: 20px 25px 0;
          border-bottom-left-radius: 26px;
          border-bottom-right-radius: 26px;
          .rank-list-item{
            line-height: 50px;
            border-bottom: 1px solid rgba(255,255,255,0.1);
            display: flex;
            .rank-list-index{
              width: 50px;
              text-align: center;
            }
            .rank-list-title{
              width: 80px;
              text-align: center;
            }
            .rank-list-num{
              flex: 1;
              text-align: right;
            }
          }
          &>div:last-child{
            border-bottom: none;
          }
        }
      }
    }
  }
  /deep/ .van-picker__confirm{
    color: #b0271d;
  }
</style>
